import React from 'react';
import { Image } from '@mantine/core';
import { useMediaQuery } from 'react-responsive';
// import GraphImage from '@graphcms/react-image';
import './Image.css';

const ContentImage = props => {
  const mobile = useMediaQuery({ maxWidth: 780 });

  // const ft = props.fit || 'max';
  if (props.background && !mobile) {
    const offsetX = props?.imageOffsetX || 0;
    const offsetY = props?.imageOffsetY || 0;
    return (
      <div className="BackgroundImage">
        <div style={{ height: '100%' }}>
          <div
            className={`BackgroundImage `}
            style={{
              backgroundSize: 'cover',
              backgroundPositionX: `${offsetX}px`,
              backgroundPositionY: `${offsetY}px`,
              backgroundImage: `url(https://sanders.clockworkbee.co.uk/assets/${props.asset.id})`
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="BackgroundImage">
      <div style={{ height: '100%' }}>
        <Image
          src={`https://sanders.clockworkbee.co.uk/assets/${props.asset.id}`}
          alt="e"
        />
      </div>
    </div>
  );
};

export default ContentImage;
